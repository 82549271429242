<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.chart_account_setting') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-lg-12">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('prefix') }}</label>
                <input type="number" v-model="data.prefix" :min="1" :max="9" :maxlength="9" :minlength="1" class="form-control"/>
                <span v-if="validation && validation.prefix" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.prefix[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('digits') }}</label>
                <input type="number" v-model="data.digits" :min="0" :max="9" :maxlength="0" :minlength="9" class="form-control"/>
                <span v-if="validation && validation.digits" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.digits[0] }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "from-chart-accounts",
  data() {
    return {
      mainRoute: 'accounting/chart-accounts-settings',
      mainRouteDependency: 'base/dependency',

      data: {
        prefix: null,
        digits: null,
      },
      isEditing: false,
      validation: null,
    };
  },
  watch: {
    "data.prefix": function (val) {
      if (!val.toString()) {
        this.data.prefix = '0';
      }
    },
    "data.digits": function (val) {
      if (!val.toString()) {
        this.data.digits = '0';
      }
    }
  },
  methods: {
    save() {

      // if (this.isEditing) {
      //     this.update();
      // } else {
      this.create();
      // }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
        // this.$router.push('/accounting/chart-account-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.prototype.$postStatus = true;
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.prototype.$postStatus = true;
        this.$successAlert(response.data.message);
        // this.$router.push('/accounting/chart-account-setting');
      }).catch((error) => {
        this.$errorAlert(error);
        this.prototype.$postStatus = true;
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    getData() {
      ApiService.get(this.mainRoute).then((response) => {
        this.isEditing = true;
        this.data.prefix = response.data.data.prefix;
        this.data.digits = response.data.data.digits;
      });
    },
  },

  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.chart_accounts"), route:'/accounting/chart-accounts'}, {title: this.$t('chart_accounts.chart_accounts_settings')}]);
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.chart_account_setting")}]);
    this.getData();

  },
};
</script>


